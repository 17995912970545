import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField, CardActions, ToggleButtonGroup, ToggleButton,} from '@mui/material'
import Footer from '../../Components/Footer/Footer';
import DemoImage from '../../Assets/man-791049_1920.jpg'
import { successCase } from '../../Variables/ProcessVariable';
import { get_success_stories1, get_success_stories2 } from '../../API Service/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Success() {

  const [Success1 , setSuccess1] = useState([]);
  const [Success2 , setSuccess2] = useState([]);
  const [Success3 , setSuccess3] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
const navigate = useNavigate();

  const getSuccess1 = () => {
      const sendData = new FormData()
      sendData.append('successStoriesId1', '');
      axios({
        method: 'POST',
        url: get_success_stories1,
        data:sendData
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setSuccess1(res.data.data)
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }

    const getSuccess2 = () => {
      const sendData = new FormData()
      sendData.append('successStoriesId2', '');
      axios({
        method: 'POST',
        url:get_success_stories2,
        data:sendData
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setSuccess2(res.data.data)
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }


  
    useEffect(() => {
      getSuccess1();
    }, []);

    useEffect(() => {
      getSuccess2();
    }, []);

    function formatDate(inputDate) {
      if (inputDate === '0000-00-00') {
        return '0000-00-00';
      }
      const dateObject = new Date(inputDate);
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      const month = months[dateObject.getMonth()];
      const day = dateObject.getDate();
      const year = dateObject.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;
      return formattedDate;
    }


    const maxLength = 100; // Set the maximum length you want to display

// Function to truncate the text
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};


const movedtoSuccess = (successid) =>{
  navigate('/detailedsuccess')
  localStorage.setItem('Successid', successid)
}


  return (
    <Box className='Banner'>
        <Header />

        <Box mt={8} sx={{cursor:'pointer'}}>
          <Container>
            <Box component={Card} p={3} onClick={()=>navigate('/detailedblog')}>
          <Grid container spacing={{lg:3 , md:3 ,sm:2 , xs:0}}>
          <Grid item xs={12} md={6} sm={6} lg={6}  
          order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
          display='flex' justifyContent='center' alignItems='center' alignContent='center'
         > 
        <Stack textAlign='left' spacing={2}>
        <Typography variant='p'>Blog Post</Typography>
        <Typography variant='h3' fontWeight={600} className='content'>
        {Success1?.blogTitle}
        </Typography>   
        <Typography variant='p'> {formatDate(Success1?.profileDate)}</Typography>         
        </Stack>
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
        <Box>
        <img src={Success1?.blogImage} alt='feature' className='featureImage' />
        </Box>
        </Grid>
        </Grid>
            </Box>
          </Container>
        </Box>

        <Container>
        <Box mt={8} p={2}>
        {/* <Box display='flex' justifyContent='end' p={1} my={2}>
      <ToggleButtonGroup
      color="primary"
      exclusive
      variant='filled'
      aria-label="Platform"
    >
      <ToggleButton value="POET">BLOG</ToggleButton>
      <ToggleButton value="CONTRACT - PRO">CASE STUDY</ToggleButton>
    </ToggleButtonGroup>
      </Box> */}

        <Grid container spacing={4}>
        {
        Success2.map((i)=>{
        return(
        <Grid item xs={12} sm={6} md={4} lg={4} onClick={()=>movedtoSuccess(i.successStoriesId2)} sx={{cursor:'pointer'}}>
        <Card sx={{borderRadius:'10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' , height:'100%'}}>
        <img src={i.images} alt="" style={{height:200, width:'100%' , objectFit:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} />
        <CardContent>
         <Stack textAlign='left' spacing={1}>
            <Typography variant='subtitle1' textTransform='uppercase'>{i.types}</Typography>
            <Typography variant='h6'>{truncateText(i.content, maxLength)}</Typography>
         </Stack>
        </CardContent>
        <CardActions>
          <Box p={1}>
          <Typography  variant='subtitle1'>{formatDate(i.dates)}</Typography>
          </Box>
        </CardActions>
        </Card>
        </Grid>
        )
        })
        }
        </Grid>
        </Box>
        </Container>

        <Footer />
        </Box>
  )
}
