import React, { useState } from 'react'
import {  Navigate, useRoutes } from 'react-router-dom'
import HomePage from './Pages/HomePage/HomePage';
import Pagenotfound from './Components/Pagenotfound';
import POET from './Pages/Products/POET/POET';
import Contract from './Pages/Products/Contract/Contract';
import Individuals from './Pages/ForWork/Individuals';
import About from './Pages/About/About';
import Teams from './Pages/ForWork/Teams';
import Success from './Pages/ForWork/Success';
import WorkEvent from './Pages/ForWork/WorkEvent';
import Students from './Pages/ForEducation/Students';
import Institutions from './Pages/ForEducation/Institutions';
import EventsReports from './Pages/ForEducation/EventsReports';
import Blog from './Pages/Blog/Blog';
import News from './Pages/News/News';
import DetailedBlog from './Pages/Blog/DetailedBlog';
import DetailedNews from './Pages/News/DetailedNews';
import DetailedSuccess from './Pages/ForWork/DetailedSuccess';
import DetailedWorkEvent from './Pages/ForWork/DetailedWorkEvent';
import DetailedEventsReports from './Pages/ForEducation/DetailedEventsReports';




export const startUrl = `/`;



function Routes() {
   const auth = localStorage.getItem('PETAuth');

    return useRoutes([
           {
            path: '*',
            element: <Pagenotfound />
            },
            {
            path: startUrl,
            element:<HomePage />
            },
            {
                path: 'poet',
                element:<POET />
            },
            {
                path:'contract',
                element:<Contract />
            },
            {
                path:'individuals',
                element:<Individuals />
            },
            {
                path:'teams',
                element:<Teams />
            },
            {
                path:'success',
                element:<Success />
            },
            {
                path:'workevent',
                element:<WorkEvent />
            },
            {
                path:'student',
                element:<Students />
            },
            {
                path:'institute',
                element:<Institutions />
            },
            {
                path:'educationevent',
                element:<EventsReports />
            },
            {
                path:'aboutus',
                element:<About />
            },
            {
                path:'blog',
                element:<Blog />
            },
            {
                path:'news',
                element:<News />
            },
            {
                path:'detailedblog',
                element:<DetailedBlog />
            },
            {
                path:'detailednews',
                element:<DetailedNews />
            },
            {
                path:'detailedsuccess',
                element:<DetailedSuccess />
            },
            {
                path:'detailedworkevent',
                element:<DetailedWorkEvent />
            },
            {
                path:'detailededucationevent',
                element:<DetailedEventsReports />
            },
    ])
}

export default Routes;