import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField,} from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Footer from '../../Components/Footer/Footer';
import DemoImage from '../../Assets/HomePage_3rd/faq-3.png';
import FetaureImage from '../../Assets/Frame_31612501.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Worktestimonials, instiutecompose, InstituteTestimonials } from '../../Variables/ProcessVariable';
import Avatar1 from '../../Assets/Testimonials/testi_avatar01.png';
import Avatar2 from '../../Assets/Testimonials/testi_avatar02.png';
import Avatar3 from '../../Assets/Testimonials/testi_avatar03.png';
import { get_institution1, get_institution2, get_institution3, get_institution4, get_institution5 } from '../../API Service/Api';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Institutions() {

    const settings1 = {
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        };
        

        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("");
        const [status, setStatus] = useState();
        const [color, setColor] = useState();
        const [Institute1 , setInstitute1] = useState([]);
        const [Institute2 , setInstitute2] = useState([]);
        const [Institute3 , setInstitute3] = useState([]);
        const [Institute4 , setInstitute4] = useState([]);
        const [Institute5 , setInstitute5] = useState([]);


        const navigate = useNavigate();

        const location = useLocation();

        const getInstitute1 = () => {
          const sendData = new FormData()
          sendData.append('institutionId1', '');
          axios({
            method: 'POST',
            url: get_institution1,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setInstitute1(res.data.data[0])
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        const getInstitute2 = () => {
          const sendData = new FormData()
          sendData.append('institutionId2', '');
          axios({
            method: 'POST',
            url: get_institution2,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setInstitute2(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        const getInstitute3 = () => {
          const sendData = new FormData()
          sendData.append('institutionId3', '');
          axios({
            method: 'POST',
            url: get_institution3,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setInstitute3(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        
        const getInstitute4 = () => {
          const sendData = new FormData()
          sendData.append('institutionId4', '');
          axios({
            method: 'POST',
            url: get_institution4,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setInstitute4(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        const getInstitute5 = () => {
          const sendData = new FormData()
          sendData.append('institutionId5', '');
          axios({
            method: 'POST',
            url: get_institution5,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setInstitute5(res.data.data[0])
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      
        useEffect(() => {
          getInstitute1();
        }, []);

        useEffect(() => {
          getInstitute2();
        }, []);

        useEffect(() => {
          getInstitute3();
        }, []);

        useEffect(() => {
          getInstitute4();
        }, []);

        useEffect(() => {
          getInstitute5();
        }, []);



        const Feature = ({ title, content, image, index}) => (
          <>
          {
           index % 2 === 0 ?
           <Grid container spacing={3}>
           <Grid item xs={12} md={6} sm={6} lg={6}  
           order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
           display='flex' justifyContent='center' alignItems='center' alignContent='center'
           > 
           <Stack textAlign='left' spacing={2}>
           <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
           <Typography variant='subtitle1' fontWeight={550} className='content'>
           {content}
           </Typography>            
           </Stack>
           </Grid>
   
           <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
           <Box>
           <img src={image} alt='feature' className='featureImage' />
           </Box>
           </Grid>
           </Grid>
             :
       
             <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
             <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:1 , md:1 , sm: 1 , xs: 1}} display='flex' justifyContent='center'>
             <Box>
             <img src={image} alt='feature' className='featureImage' />
             </Box>
             </Grid>
     
             <Grid item xs={12} md={6} sm={6} lg={6}  
             order={{lg:2 , md:2 , sm: 2 , xs: 2}} 
             display='flex' justifyContent='center' id='rewrite'
             >
             <Stack textAlign='left' spacing={2}>
             <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
             <Typography variant='subtitle1' fontWeight={550} className='content'>
            {content}
             </Typography>            
             </Stack>
             </Grid>
             </Grid>
          }
          </>
         );

         const handleTryPoetClick = () => {
          window.open('https://www.mypoet360.com/signup', '_blank');
        };

        const handleContactUs = (id) => {
          navigate(`/#${id}`);
            setTimeout(() => {
            const element = document.getElementById(`${id}`);
            if (element) {
              element.scrollIntoView({ behavior: "smooth"  });
            }
          }, location.pathname === '/' ? 0 : 2000);
          }; 


  return (
    <Box className='Banner'>
        <Header />

       <Container>
        <Box mt={8}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
        <Stack spacing={3} textAlign='left'>
        <Typography variant='h4' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>{Institute1.title}</Typography>
        <Typography lineHeight={1.5} variant='h6' mb='20px'>
        {Institute1.content}
        </Typography>
        <Box flexDirection='row' gap={5} display='flex' justifyContent='start'>
        <Button variant='contained'        
        sx={{bgcolor:'#135DA8' ,width:'15ch', fontSize:'18px' , color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}} onClick={()=>handleContactUs("QuoteSection")}>
           Contact Sales
        </Button>
        <Button variant='contained'  sx={{bgcolor:'#CECECE' , fontSize:'18px' , color:'#000' ,fontWeight:600 , textTransform : 'capitalize', ':hover':{bgcolor:'#CECECE'}}} onClick={()=>handleTryPoetClick()}>
          Sign Up with Your School
        </Button> 
        </Box>
        </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} position='relative' mt={{lg:0, md:0 , sm:3 , xs:4}}>
        <Box>
        <img src={Institute1.images} alt='demo' className='demoImage' />
        </Box>
        </Grid>

        </Grid>   
        </Box>
        </Container>


        <Container>
       <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
       <Typography variant='h2' className='Banner2Title'>
       Accelerate Your Progress: Collaborate with a Writing Partner for Swift Success
       </Typography>

       <Stack spacing={3}  mt={5}>

       {Institute2.map((feature, index) => (
            <Feature
              key={index}
              index={index}
              title={feature.title}
              content={feature.content}
              image={feature.images}
            />
          ))}
            </Stack>
      </Box>
      </Container>

        
      <Box sx={{backgroundColor:'#135DA8', textAlign:'justify', color:'white'}} mt={5}>
    <Container>
        <Box py={3}>
      <Grid container spacing={8} display='flex' justifyContent='center'> 
        {
           Institute3.map((i)=>{
                return(
                    <Grid item xs={6} sm={6} md={4} lg={4} textAlign='justify'>
                        <Stack spacing={2}>
                            <Box>
                            <img src={i.images} alt="file" width="60" height="60" />
                            </Box>
                          <Typography variant='h6'>{i.title}</Typography>
                         <Typography variant='p' width='70%'>{i.content}</Typography>
                         <Stack spacing={1}>
                         <Typography variant='p'>{i.name}</Typography>
                         <Typography variant='p' width='70%'>{i.post}</Typography>
                         </Stack>
                        </Stack>
                    </Grid>  
                )
            })
        }
        </Grid> 
        </Box>
        </Container>
      </Box>

      <Container>
      <Box mt={10}>
      <Grid container spacing={3} display='flex' justifyContent='center'> 
        {
            Institute4.map((i)=>{
                return(
                    <Grid item xs={12} sm={6} md={2} lg={2} textAlign='left'>
                        <Stack spacing={2}>
                        <img src={i.images} alt="file" width="60" height="60" />
                        <Typography variant='p' width='70%'>{i.content}</Typography>
                        </Stack>
                    </Grid>  
                )
            })
        }
        </Grid>   
      </Box>
      </Container>

      <Container>
        <Box mt={7}>
            <Stack spacing={2}>
            <Typography variant='h3' fontWeight={600}  fontSize={{lg:'34px', md:'32px', sm:'28px', xs:'22px'}}>{Institute5.title}</Typography>
            <Box display='flex' justifyContent='center'>
        <Typography width={{lg:'70%', md:'70%',sm:"80%", xs:'90%' }} variant='h6'>{Institute5.content}</Typography>
        </Box>
            <Box pt={2}>
            <Button variant='contained'        
            sx={{bgcolor:'#135DA8' ,  fontSize:'18px' , color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}} onClick={()=>handleContactUs("QuoteSection")}>
            Contact Us
            </Button>
            </Box>
            </Stack>
        </Box>
      </Container>
           

        <Footer />
    </Box>
  )
}
