import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid , Stack , Container, Pagination } from '@mui/material';
import cardImage from '../../Assets/empower__2_.webp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer';
import { eventCard } from '../../Variables/ProcessVariable';
import { useNavigate } from 'react-router-dom';
import { get_events_reports1, get_events_reports2, get_events_reports3, get_work_events_reports } from '../../API Service/Api';
import axios from 'axios';


export default function WorkEvent() {



    const handleClickOpenNameEdit = (id) => {
        setTimeout(() => {
          const element = document.getElementById(`${id}`);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0);
      };



      const [Section1 , setSection1] = useState([]);
      const [Events , setEvents] = useState([]);
      const [Reports , setReports] = useState([]);
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = useState();
      const [color, setColor] = useState();

      const itemsPerPage = 5;
      const EventtotalPages = Math.ceil(Events.length / itemsPerPage);
      const reportPages = Math.ceil(Reports.length / itemsPerPage);
      const [currentPage, setCurrentPage] = useState(1);
      const [value, setValue] = React.useState(0);
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentEventItems = Events?.slice(indexOfFirstItem, indexOfLastItem);
      const currentreportItems = Reports?.slice(indexOfFirstItem, indexOfLastItem);
      const navigate = useNavigate();
      const containerRef = useRef();
    
      const handlePageChange = (event, newValue) => {
        setCurrentPage(newValue);
        if (containerRef.current) {
          containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    


    
      const getSection1 = () => {
          const sendData = new FormData()
          sendData.append('eventsReportsId1', '');
          axios({
            method: 'POST',
            url: get_events_reports1,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setSection1(res.data.data[0])
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        useEffect(() => {
          getSection1();
        }, []);
    
    
        const getEvents = () => {
          const sendData = new FormData()
          sendData.append('eventReportId', '');
          sendData.append('section', 'Events');
          axios({
            method: 'POST',
            url: get_work_events_reports,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setEvents(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      
        useEffect(() => {
          getEvents();
        }, []);
      

        const getReports = () => {
          const sendData = new FormData()
          sendData.append('eventReportId', '');
          sendData.append('section', 'Reports');
          axios({
            method: 'POST',
            url: get_work_events_reports,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setReports(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      
        useEffect(() => {
          getReports();
        }, []);


        const maxLength = 100; // Set the maximum length you want to display

        // Function to truncate the text
        const truncateText = (text, maxLength) => {
          if (text?.length > maxLength) {
            return `${text?.slice(0, maxLength)}...`;
          }
          return text;
        };

        const movedtoEvent = (Eventid , EventType) =>{
          navigate('/detailedworkevent')
          localStorage.setItem('Eventid',Eventid )
          localStorage.setItem('EventType', EventType)
        }
      
      
        function formatDate(inputDate) {
          if (inputDate === '0000-00-00') {
            return '0000-00-00';
          }
          const dateObject = new Date(inputDate);
          const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ];
          const month = months[dateObject.getMonth()];
          const day = dateObject.getDate();
          const year = dateObject.getFullYear();
          const formattedDate = `${month} ${day}, ${year}`;
          return formattedDate;
        }
        


  return (
    <Box className='Banner'>
    <Header />

    <Box textAlign='center'>
      <Stack spacing={4} style={{ backgroundColor: '#135DA8', color: 'white'}} py={8}>
      <Typography variant='h4' sx={{ fontSize:{lg:'34px', md:'32px', sm:'28px', xs:'22px'}}}>{Section1.title}</Typography>
        <Box display='flex' justifyContent='center'>
        <Typography width={{lg:'70%', md:'70%',sm:"80%", xs:'90%' }} variant='h6'>{Section1.content}</Typography>
        </Box>
        <Typography variant='h6'>Jump to section:</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , gap:'20px' }}>
          <Button onClick={()=> handleClickOpenNameEdit('eventsection')} style={{ color: '#1F243C', fontWeight: '700', backgroundColor: 'white' }} variant="contained" size="small"> Events</Button>
          <Button onClick={()=> handleClickOpenNameEdit('reportsection')} style={{ color: '#1F243C', fontWeight: '700', backgroundColor: 'white'}} variant="contained" size="small">Reports</Button>
        </Box>
      </Stack>
      </Box>

        <Container>
        <Box mt={8}>
        <Box mb={2}>
        <Typography variant='h4' id='eventsection'>Events</Typography>
        </Box>

      


        <Grid container spacing={4} ref={containerRef}>
        {currentEventItems.map((i) => (
          <Grid item xs={12} sm={6} md={4} lg={4} sx={{cursor:'pointer'}} key={i.eventReportId} onClick={()=>movedtoEvent(i.eventReportId, 'Events')}>
          <Card sx={{borderRadius:'10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' ,  height:'100%'}}>
          <img src={i.images} alt="" style={{height:200, width:'100%' , objectFit:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} />
          <CardContent>
          <Stack spacing={1} textAlign='left'>
          <Typography variant='h6' fontWeight={600}>
          {i.title}
          </Typography>
          <Typography variant='body1'>{truncateText(i.content, maxLength)}</Typography>
          <Typography variant='subtitle2'>{formatDate(i.profileDate)}</Typography>
          </Stack>
          </CardContent>
          <CardActions sx={{display:'flex', justifyContent:'end'}}>
            <Box display='flex' flexDirection='row' justifyContent='end'>
            <Button size="large"><ArrowForwardIcon /></Button>
            </Box>
        </CardActions>
          </Card>
          </Grid>
        ))}
        </Grid>
   
    
        </Box>
        </Container>

        <Container>
        <Box mt={8}>
        <Box mb={2}>
        <Typography variant='h4' id='reportsection'>Reports</Typography>
        </Box>


          
        <Grid container spacing={4} ref={containerRef}>
        {currentreportItems.map((i) => (
          <Grid item xs={12} sm={6} md={4} lg={4} px={1} sx={{cursor:'pointer'}} key={i.eventReportId} onClick={()=>movedtoEvent(i.eventReportId, 'Reports')}>
          <Card sx={{borderRadius:'10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' , height:'100%' }}>
          <img src={i.images} alt="" style={{height:200, width:'100%' , objectFit:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} />
          <CardContent>
          <Stack spacing={1} textAlign='left'>
          <Typography variant='h6' fontWeight={600}>
          {i.title}
          </Typography>
          <Typography variant='body1'>{truncateText(i.content, maxLength)}</Typography>
          <Typography variant='subtitle2'>{formatDate(i.profileDate)}</Typography>
          </Stack>
          </CardContent>
          <CardActions sx={{display:'flex', justifyContent:'end'}}>
            <Box display='flex' flexDirection='row' justifyContent='end'>
            <Button size="large"><ArrowForwardIcon /></Button>
            </Box>
        </CardActions>
          </Card>
          </Grid>
        ))}
        </Grid>

        </Box>
        </Container>


    <Footer />
    </Box>
  )
}
