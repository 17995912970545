import React, { useEffect, useState } from "react";
import { BiMenuAltRight, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from '../../Assets/Logos/logo5-removebg-preview.png';
import Logo1 from '../../Assets/Logos/logo1-removebg-preview.png';
import { Box, Button, Menu, MenuItem, Stack, Typography , Collapse, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { get_product_title } from "../../API Service/Api";
import axios from "axios";

function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [workOpen , setWorkOpen] = useState(false);
  const[POETDrop , setPOETDrop] = useState([])
  const[ContractDrop , setContractDrop] = useState([])
  const [productOpen , setproductOpen] = useState(false);
  const [educationOpen , seteducationOpen] = useState(false);
  const [resourceOpen , setresourceOpen] = useState(false);
  const [ProductanchorEl, setProductAnchorEl] = React.useState(null);
  const openProduct = Boolean(ProductanchorEl);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeLink, setActiveLink] = useState('');

  const location = useLocation();

  // Update the active link based on the current path
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const SignInOpen = Boolean(anchorEl);
  const handleSignInClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSignInClose = () => {
    setAnchorEl(null);
  };


  const handleCloseProduct = () => {
    setProductAnchorEl(null);
  };

  const handleOpenProduct = (event) => {
    if(!menuOpen){
      setProductAnchorEl(event.currentTarget);
    }
    else{
      setproductOpen(!productOpen);
      seteducationOpen(false);
      setresourceOpen(false);
      setWorkOpen(false)
  }
}

  const gotoProducts = (item) =>{
    navigate(`/${item}`)
  }

  const [BloganchorEl, setBlogAnchorEl] = React.useState(null);
  const openBlog = Boolean(BloganchorEl);

  // console.log(openBlog , 'openBlog');
  // console.log(BloganchorEl , 'BloganchorEl');

  const handleCloseBlog = () => {
    setBlogAnchorEl(null);
  };

  const handleOpenBlog = (event) => {
    if(!menuOpen){
      setBlogAnchorEl(event.currentTarget);
    }
  else{
    setresourceOpen(!resourceOpen);
    seteducationOpen(false);
    setWorkOpen(false)
    setproductOpen(false);
  }  };


  console.log(menuOpen)
  console.log(resourceOpen)


  const gotoBlog = (item) =>{
    navigate(`/${item}`)
  }


  const [WorkanchorEl, setWorkAnchorEl] = React.useState(null);
  const openWork = Boolean(WorkanchorEl);

  const handleCloseWork = () => {
    setWorkAnchorEl(null);
  };

  const handleOpenWork = (event) => {
    if(!menuOpen){
    setWorkAnchorEl(event.currentTarget);
    }
    else{
      setWorkOpen(!workOpen);
      seteducationOpen(false);
      setproductOpen(false);
      setresourceOpen(false);
    }
  };


  const gotoWork = (item) =>{
    navigate(`/${item}`)
  }

  const [EducationanchorEl, setEducationAnchorEl] = React.useState(null);
  const openEducation = Boolean(EducationanchorEl);

  const handleCloseEducation = () => {
    setEducationAnchorEl(null);
  };

  const handleOpenEducation = (event) => {
    if(!menuOpen){
    setEducationAnchorEl(event.currentTarget);
    }
    else{
      seteducationOpen(!educationOpen);
      setproductOpen(false);
      setresourceOpen(false);
      setWorkOpen(false)
    }
  };


  const gotoEducation= (item) =>{
    navigate(`/${item}`)
  }



  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 970 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen(!menuOpen);
  };


  window.addEventListener("scroll", function() {
    const navBar = document.querySelector(".NavBar");
    const header = document.querySelector(".header");
    const offset = header.offsetHeight;
  
    if (window.scrollY >= offset) {
      navBar.classList.add("sticky");
    } else {
      navBar.classList.remove("sticky");
    }
  })

  const getPOETDrop = () => {
    const sendData = new FormData()
    sendData.append('productName', 'POET')
    axios({
        method: 'POST',
        url: get_product_title,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setPOETDrop(res.data.data);
        }
    }).catch(err => {
        alert('Oops something went wrong ' + err)
    });
}

useEffect(()=>{
  getPOETDrop();
},[])

const getContractDrop = () => {
  const sendData = new FormData()
  sendData.append('productName', 'CONTRACT - PRO')
  axios({
      method: 'POST',
      url: get_product_title,
      data:sendData
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setContractDrop(res.data.data);
      }
  }).catch(err => {
      alert('Oops something went wrong ' + err)
  });
}

useEffect(()=>{
  getContractDrop();
},[])



  // const handleClickOpenNameEdit1 = (page, title) => {
  //   handleCloseProduct()
  //   const slideCategoryId = title.replace(/\s+/g, '').toLowerCase();
  //   navigate(`/${page}#${slideCategoryId}`);
  // };


  const handleClickOpenNameEdit = (page, title) =>{
     handleCloseProduct()
    const slideCategoryId = title.replace(/\s+/g, '').toLowerCase();
    navigate(`/${page}#${slideCategoryId}`);

    const scrollTimeout = setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);

    // Clear the timeout if the user manually scrolls before it triggers
    window.addEventListener('scroll', () => {
      clearTimeout(scrollTimeout);
    });
  }


  
  


  const handleTryPoetClick = () => {
    window.open('https://www.mypoet360.com/login', '_blank');
  };

  const handleTryContractProClick = () => {
    window.open('https://www.contractpro360.com/login', '_blank');
  };


  const forWork = ['/individuals', '/teams', '/success', '/workevent'];
  const foreducation = ['/student', '/institute', '/educationevent'];
  const forproducts = ['/poet', '/contract'];
  const forresources = ['/blog', '/news'];
  



  return (
    <Box className='NavBar' >
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__content__logo">
         <img  src={location.pathname === '/contract' ? Logo : Logo1} alt='header' style={{objectFit:'contain' , width:220 , height:150}} />
        </Link>
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 970 ? `${"isMenu"}` : ""} 
          }`}
        >
          <ul>
            <li style={{marginBottom: !menuOpen && '5px'}}>
              <Link to="/" style={{ color: activeLink === '/' ? '#135DA8' : 'black', textDecoration: 'none'}} >Home</Link>
            </li>
            <li style={{marginBottom: !menuOpen && '5px'}}>
              <Link to="/aboutus"  style={{ color: activeLink === '/aboutus' ? '#135DA8' : 'black', textDecoration: 'none'}}>About</Link>
            </li>
            <li>
              <Link style={{cursor:'pointer', color: forWork.includes(activeLink) ? '#135DA8' : 'black', textDecoration: 'none'}} onClick={handleOpenWork}>For Work<ExpandMoreIcon sx={{verticalAlign:'top'}} />
              </Link>
              {
                !menuOpen ? 
                <Menu
                anchorEl={WorkanchorEl}
                id="account-menu"
                open={openWork}
                onClose={handleCloseWork}
                PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 10,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
                },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                <Stack spacing={2} p={2}>
                <MenuItem onClick={()=>gotoWork('individuals')}>
                <Typography fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/individuals' ? '#135DA8' : 'black'}}>
                Individuals
                </Typography>
                </MenuItem>
    
                <MenuItem onClick={()=>gotoWork('teams')}>
                <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/teams' ? '#135DA8' : 'black'}}>
                Teams & Businesses
                </Typography>
                </MenuItem>
                <MenuItem onClick={()=>gotoWork('success')}>
                <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/success' ? '#135DA8' : 'black'}}>
                Success Stories
                </Typography>
                </MenuItem>
                <MenuItem onClick={()=>gotoWork('workevent')}>
                <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/workevent' ? '#135DA8' : 'black'}}>
                Business Events & Reports
                </Typography>
                </MenuItem>
                </Stack>
                </Menu>
                :
                <>
                {
                  workOpen && 
                  <Stack spacing={2} py={2} textAlign='right'>
                  <Typography onClick={()=>gotoWork('individuals')}>Individuals</Typography>
                  <Typography  onClick={()=>gotoWork('teams')}> Teams & Businesses</Typography>
                  <Typography onClick={()=>gotoWork('success')}>Success Stories</Typography>
                  <Typography onClick={()=>gotoWork('workevent')}>Business Events & Reports</Typography>
                   </Stack>
                }
                </>
              }

            </li>
            <li>
              <Link style={{cursor:'pointer',color: foreducation.includes(activeLink) ? '#135DA8' : 'black', textDecoration: 'none'}} onClick={handleOpenEducation}>For Education<ExpandMoreIcon sx={{verticalAlign:'top'}} />
              </Link>
              {
                !menuOpen ? 
              <Menu
            anchorEl={EducationanchorEl}
            id="account-menu"
            open={openEducation}
            onClose={handleCloseEducation}
            PaperProps={{
            elevation: 0,
            sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            },
            '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 10,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            },
            },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
            <Stack spacing={2} p={2}>
            <MenuItem onClick={()=>gotoEducation('student')}>
            <Typography fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/student' ? '#135DA8' : 'black'}}>
            Students
            </Typography>
            </MenuItem>

            <MenuItem onClick={()=>gotoEducation('institute')}>
            <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/institute' ? '#135DA8' : 'black'}}>
            Institutions
            </Typography>
            </MenuItem>
            <MenuItem onClick={()=>gotoEducation('educationevent')}>
            <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/educationevent' ? '#135DA8' : 'black'}}>
            Education Events & Reports
            </Typography>
            </MenuItem>
            </Stack>
            </Menu>
              :
              <>
              {
                educationOpen && 
                <Stack spacing={2} py={2} textAlign='right'>
                <Typography onClick={()=>gotoEducation('student')}>Students</Typography>
                <Typography  onClick={()=>gotoEducation('institute')}> Institutions</Typography>
                <Typography onClick={()=>gotoEducation('educationevent')}> Education Events & Reports</Typography>
                 </Stack>
              }
              </>
            }
            </li>
            <li>
              <Link style={{cursor:'pointer',color: forproducts.includes(activeLink) ? '#135DA8' : 'black', textDecoration: 'none'}} onClick={handleOpenProduct}>Products<ExpandMoreIcon sx={{verticalAlign:'top'}} />
              </Link>
              {
                !menuOpen ? 
            <Menu
            anchorEl={ProductanchorEl}
            id="account-menu"
            open={openProduct}
            onClose={handleCloseProduct}
            PaperProps={{
            elevation: 0,
            sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            },
            '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: '50%',
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            },
            },
            }}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                  <MenuItem onClick={()=>gotoProducts('poet')}>
                  <Typography  fontSize='19px' fontWeight={600} sx={{color:'#135DA8'}}>
            POET
            </Typography>
            </MenuItem>

            <Stack spacing={3} p={2}>
              {
                POETDrop.slice(0,7).map((i)=>{
                  return(
                  <Box textAlign='left'>
                  <Typography variant="body1"  onClick={()=>handleClickOpenNameEdit('poet' , i.title)} 
                  sx={{textTransform:'capitalize', color:'#000', cursor:'pointer',
                  ':hover':{color:'#135DA8'}
                  }}>{i.title}</Typography>
                  </Box>
                 
                )})
              }
            </Stack>
                  </Grid>
                  <Grid item xs={6}>
                  <MenuItem onClick={()=>gotoProducts('contract')}>
            <Typography  fontSize='19px' fontWeight={600} sx={{color:'#135DA8'}}>
            Contract-Pro
            </Typography>
            </MenuItem>
           
            <Stack spacing={3} p={2}>
              {
                ContractDrop.map((i)=>{
                  return(
                    <Box textAlign='left'>
                    <Typography variant="body1" onClick={()=>handleClickOpenNameEdit('contract' , i.title)} 
                    sx={{textTransform:'capitalize', color:'#000',cursor:'pointer',
                    ':hover':{color:'#135DA8'}
                  }}
                    >{i.title}</Typography>
                    </Box>
                )})
              }
            </Stack>
                    </Grid>
                </Grid>
              </Box>

            </Menu>
  :
  <>
  {
    productOpen && 
    <Stack spacing={2} py={2} textAlign='right'>
    <Typography onClick={()=>gotoProducts('poet')}>  POET</Typography>
    <Typography onClick={()=>gotoProducts('contract')}>Contract-Pro</Typography>
     </Stack>
  }
  </>
}
            </li>

            <li >
            <Link style={{cursor:'pointer', color: forresources.includes(activeLink) ? '#135DA8' : 'black', textDecoration: 'none'}} onClick={handleOpenBlog}>Resources<ExpandMoreIcon sx={{verticalAlign:'top'}} />
            </Link>
            {
            !menuOpen ? 
            <Menu
            anchorEl={BloganchorEl}
            id="account-menu"
            open={openBlog}
            onClose={handleCloseBlog}
            PaperProps={{
            elevation: 0,
            sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            },
            '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 10,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            },
            },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
            <Stack spacing={2} p={2}>
            <MenuItem onClick={()=>gotoBlog('blog')} >
            <Typography fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/blog' ? '#135DA8' : 'black'}}>
            Blog
            </Typography>
            </MenuItem>

            <MenuItem onClick={()=>gotoBlog('news')} >
            <Typography  fontSize='18px' sx={{':hover':{color:'#135DA8'}}} style={{color: activeLink === '/news' ? '#135DA8' : 'black'}}>
            News & Updates
            </Typography>
            </MenuItem>
            </Stack>
            </Menu>
            :
            <>
            { resourceOpen &&
            <Stack spacing={2} py={2} textAlign='right'>
            <Typography onClick={()=>gotoBlog('blog')}> Blog</Typography>
            <Typography onClick={()=>gotoBlog('news')}>News & Updates</Typography>
            </Stack>
            }
            </>
            }
            </li>

            <li>
            
            <Button variant='contained'        
            sx={{bgcolor:'#135DA8' ,width:'15ch',  borderRadius:'10px', color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}} onClick={(e)=>handleSignInClick(e)}>
            Sign In</Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={SignInOpen}
              onClose={()=>handleSignInClose()}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={()=>handleTryPoetClick()}>POET</MenuItem>
              <MenuItem onClick={()=>handleTryContractProClick()}>Contract-Pro</MenuItem>
            </Menu>
          
            </li>
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} style={{zIndex:99}} />
           
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} style={{zIndex:99}} />
          )}
        </div>
      </div>
    </header>
    </Box>
  );
}

export default Header;