import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Pagination } from '@mui/material';
import Button from '@mui/material/Button';
import { Grid , Stack , Container, CardActions, Avatar } from '@mui/material';
import cardImage from '../../Assets/empower__2_.webp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer';
import { eventCard } from '../../Variables/ProcessVariable';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Image from '../../Assets/news.png';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AvatarImage from '../../Assets/Testimonials/testi_avatar04.png';
import { get_blog_product_company, get_news_product_company, get_success_stories2, get_work_events_reports } from '../../API Service/Api';
import ReactMarkdown from 'react-markdown'

export default function DetailedWorkEvent() {



  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const id = localStorage.getItem('Eventid') || "";
  const type =  localStorage.getItem('EventType') || "";

  const getEvents = () => {
    const sendData = new FormData()
    sendData.append('eventReportId', id);
    sendData.append('section', type);
    axios({
      method: 'POST',
      url: get_work_events_reports,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getEvents();
  }, []);

  const paragraphs = data?.content?.split('\n');

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  function formatDate(inputDate) {
    if (inputDate === '0000-00-00') {
      return '0000-00-00';
    }
    const dateObject = new Date(inputDate);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }

  return (
    <Box className='Banner'>
    <Header />

    <Container>

          <Grid container mt={{lg:5 , md:5 , sm:8, xs:2}} spacing={{lg:0, md:0, sm:3, xs:1}} display='flex' justifyContent={{lg:'end', md:'end', sm:'center', xs:'center'}} alignContent='start' alignItems='start'>
           <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box p={3}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6} sm={6} lg={6}  
            order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
            display='flex' justifyContent='center' alignItems='center' alignContent='center'
            > 
            <Stack textAlign='left' spacing={2}>
            <Typography variant='p'>{type}</Typography>
            <Typography variant='h3' fontWeight={600} className='content'>
            {data?.title}
            </Typography>   
            <Typography variant='p'> {formatDate(data?.profileDate)}</Typography>         
            </Stack>
            </Grid>

            <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
            <Box>
            <img src={data?.images} alt='feature' className='featureImage' />
            </Box>
            </Grid>
            </Grid>
            </Box>
           </Grid>

           <Grid item xs={12} sm={9} md={9} lg={9} display='flex' flexDirection='column' justifyContent='start'>
          <Stack display='flex' spacing={2} flexDirection='column' justifyContent='start' textAlign='left'>
          <ReactMarkdown>{data?.content}</ReactMarkdown>
          </Stack>
           </Grid>


        </Grid>

    </Container>

    <Footer />
    </Box>
  )
}
