import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Pagination } from '@mui/material';
import Button from '@mui/material/Button';
import { Grid , Stack , Container, CardActions, Avatar } from '@mui/material';
import cardImage from '../../Assets/empower__2_.webp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer';
import { eventCard } from '../../Variables/ProcessVariable';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Image from '../../Assets/news.png';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AvatarImage from '../../Assets/Testimonials/testi_avatar04.png';
import { get_blog_product_company } from '../../API Service/Api';
import ReactMarkdown from 'react-markdown'


export default function DetailedBlog() {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const id = localStorage.getItem('Blogid') || "";
  const type = localStorage.getItem('BlogType') || "";

  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('id', id );
    sendData.append('type', type);
    axios({
      method: 'POST',
      url: get_blog_product_company,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useLayoutEffect(() => {
    getAllData();
  }, []);

  const paragraphs = data?.blogContent?.split('\n');

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  function formatDate(inputDate) {
    if (inputDate === '0000-00-00') {
      return '0000-00-00';
    }
    const dateObject = new Date(inputDate);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }


  return (
    <Box className='Banner'>
    <Header />

    <Container>

        <Box mt={6} display='flex' justifyContent='center'>
        <Typography variant='h3' width={{lg:'70%', md:'70%',sm:"80%", xs:'90%' }} sx={{wordBreak:'break-word', wordWrap:'break-word', fontSize:{lg:'34px', md:'32px', sm:'28px', xs:'22px'}}}>
         {data?.blogTitle}
        </Typography>
        </Box>

          <Grid container mt={{lg:5 , md:5 , sm:8, xs:2}} spacing={{lg:0, md:0, sm:3, xs:1}} display='flex' justifyContent={{lg:'end', md:'end', sm:'center', xs:'center'}} alignContent='start' alignItems='start'>
           <Grid item xs={12} sm={3} md={3} lg={3}  display='flex'  flexDirection='column' justifyContent={{lg:'end', md:'end', sm:'end', xs:'center'}} alignContent={{lg:'end', md:'end', sm:'center', xs:'center'}} alignItems={{lg:'end', md:'end', sm:'center', xs:'center'}}>
            <Stack spacing={1.5} mt={4}>
            <img src={data?.profileImage} alt='' style={{width:70 , height:70 , verticalAlign:'middle'}} />
            <Typography variant='body1' fontWeight={600}>{data?.profileName}</Typography>
            <Box>
            <Typography variant='subtitle2'>Updated On</Typography>
            <Typography variant='subtitle2'>{formatDate(data?.profileDate)}</Typography>
            </Box>
            <Typography variant='body1' fontWeight={600}>{data?.profilePosition}</Typography>
            <Typography variant='subtitle1' textTransform='uppercase' sx={{color:'#135AD8'}} fontWeight={700}>{type}</Typography>
            </Stack>
           </Grid>

           <Grid item xs={12} sm={9} md={9} lg={9} display='flex' flexDirection='column' justifyContent='start'>
            <Stack display='flex' flexDirection='column' justifyContent='start'>
            <img src={data?.blogImage} alt='' style={{width:'100%', maxHeight:400 , objectFit:'contain'}} />
            <Stack textAlign='left' spacing={2} p={2}>
            <ReactMarkdown>{data?.blogContent}</ReactMarkdown>
            </Stack>
            </Stack>
           </Grid>
        </Grid>

    </Container>

    <Footer />
    </Box>
  )
}
