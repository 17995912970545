import React, { useEffect, useRef, useState } from 'react'
import '../POET/POET.css';
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent,} from '@mui/material'
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import featureboxImg from '../../../Assets/featurebox.png';
import grammercheck from '../../../Assets/POET/grammer.jpg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ChoosePlan } from '../../../Variables/ProcessVariable';
import { get_pricing, get_product } from '../../../API Service/Api';
import axios from 'axios';
import BasicSvgComponent from '../../../Components/SVG/BasicSvg';
import StandardSvg from '../../../Components/SVG/StandardSvg';
import Logo from '../../../Assets/Logos/Outlook-Logo_9_Con.png';

export default function Contract() {

  const [data , setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const gridRefRight1 = useRef(null);
  const gridRefLeft1 = useRef(null);
  const gridRefRight2 = useRef(null);
  const gridRefLeft2 = useRef(null);
  const gridRefRight3 = useRef(null);
  const gridRefLeft3 = useRef(null);
  const gridRefRight4 = useRef(null);
  const gridRefLeft4 = useRef(null);
  const gridRefRight5 = useRef(null);
  const gridRefLeft5 = useRef(null);
  const gridRefRight6 = useRef(null);
  const gridRefLeft6 = useRef(null);
  const [plans , setPlan] = useState([]);
  const [subscribeType , setSubscribeType] = useState('Monthly');
  useEffect(() => {
    const animateGrid = (ref, animationClass) => {
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(animationClass);
              observer.unobserve(entry.target);
            }
          });
        });

        observer.observe(ref.current);
      }
    };

    animateGrid(gridRefRight1, 'animate-slide-right');
    animateGrid(gridRefLeft1, 'animate-slide-left');
    animateGrid(gridRefRight2, 'animate-slide-right');
    animateGrid(gridRefLeft2, 'animate-slide-left');
    animateGrid(gridRefRight3, 'animate-slide-right');
    animateGrid(gridRefLeft3, 'animate-slide-left');
    animateGrid(gridRefRight4, 'animate-slide-right');
    animateGrid(gridRefLeft4, 'animate-slide-left');
    animateGrid(gridRefRight5, 'animate-slide-right');
    animateGrid(gridRefLeft5, 'animate-slide-left');
    animateGrid(gridRefRight6, 'animate-slide-right');
    animateGrid(gridRefLeft6, 'animate-slide-left');
  }, []);


  const cardRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const animateCards = (refs, animationClass) => {
      refs.forEach((ref, index) => {
        if (ref.current) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add(animationClass);
                observer.unobserve(entry.target);
              }
            });
          });

          observer.observe(ref.current);
        }
      });
    };

    animateCards(cardRefs.slice(0, 2), 'animate-group-1'); 
    animateCards(cardRefs.slice(2, 4), 'animate-group-2');
  }, []);



  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('productId', '');
    sendData.append('productName', 'CONTRACT - PRO');
    axios({
      method: 'POST',
      url: get_product,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);

  const Feature = ({ title, content, image, tryButtonLabel , index}) => (
    <>
    {
     index % 2 === 0 ?
     <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
     <Grid item xs={12} md={6} sm={6} lg={6}  
     order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
     display='flex' justifyContent='center' ref={gridRefRight1} id={title.replace(/\s+/g, '').toLowerCase()}
     >
         <Box className='featurebox' position='relative'>
           <img src={featureboxImg} alt='feature' className='featureImagebox' />
           <Box className='contentBox'>
           <Typography variant='h4' fontWeight={600} className='title'>{title}</Typography>
           <Typography variant='subtitle2' fontWeight={550} className='content'>
           {content}
           </Typography>
                             
           </Box>
           <Box className='circlenumber'>
            {index + 1}
         </Box>
         </Box>
     </Grid>
 
     <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} ref={gridRefLeft1} display='flex' justifyContent='center'>
       <Box className='ImageBox'>
        <img src={image} alt='feature' className='featureImage' />
        <Box className='tryButton'>
        <Button variant='contained'        
        sx={{bgcolor:'#8DA5DB' , borderRadius:'10px', color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}} onClick={()=>handleTryContractProClick()}>
        TRY FOR FREE
       </Button>
       </Box>
       </Box>
       </Grid>
       </Grid>
       :
 
       <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
             <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:1 , md:1 , sm: 1 , xs: 1}} ref={gridRefRight2} display='flex' justifyContent='center'>
             <Box className='ImageBox'>
              <img src={image} alt='feature' className='featureImage' />
              <Box className='tryButton'>
              <Button variant='contained'        
              sx={{bgcolor:'#8DA5DB' , borderRadius:'10px', color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}} onClick={()=>handleTryContractProClick()}>
              TRY FOR FREE
             </Button>
             </Box>
             </Box>
             </Grid>
 
           <Grid item xs={12} md={6} sm={6} lg={6}  
           order={{lg:2 , md:2 , sm: 2 , xs: 2}} 
           display='flex' justifyContent='center' ref={gridRefLeft2} id={title.replace(/\s+/g, '').toLowerCase()}
           >
               <Box className='featurebox' position='relative'>
                 <img src={featureboxImg} alt='feature' className='featureImagebox' />
                 <Box className='contentBox'>
                 <Typography variant='h4' fontWeight={600} className='title'>{title}</Typography>
                 <Typography variant='subtitle2' fontWeight={550} className='content'>
                 {content}
                 </Typography>
                                   
                 </Box>
                 <Box className='circlenumber'>
                 {index + 1}
               </Box>
               </Box>
           </Grid>
             </Grid>
    }
    </>
   );
   


   const getPrincing = (subscribe) => {
    const sendData = new FormData()
    sendData.append('pricingId', '');
    sendData.append('subscriptionType', subscribe);
    sendData.append('productName', 'CONTRACT - PRO');
    axios({
      method: 'POST',
      url: get_pricing,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setPlan(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getPrincing('Monthly');
  }, []);

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }, []);

  const handleTryContractProClick = () => {
    window.open('https://www.contractpro360.com/login', '_blank');
  };


  return (
    <div>
      <Box className='Banner'>
      <Header />


      <Container>
      <Stack spacing={2} textAlign='center' mt={7} mb={4}>
      <Box display='flex' flexDirection='row' justifyContent='center'>
      <Typography variant='h2' className="xc-faq-three__img w-img" data-tilt="">
        CONTRACT-PRO
      </Typography>
      <img  src={Logo} alt='header' style={{objectFit:'contain' , width:180 ,margin: '15px 15px 0px'  ,verticalAlign:'middle'}} className='pen' />
      </Box>
      <Typography>
      Contract-Pro is an AI-assisted proposal generation tool designed for creating compelling proposals for federal government contracts.One of ContactPro's standout features is its ability to generate engaging content that resonates with evaluators.
      </Typography>
      <Box display='flex' justifyContent='center'>
      <Button variant='contained'        
       sx={{bgcolor:'#135DA8' , borderRadius:'10px', color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}} onClick={()=>{handleTryContractProClick()}}>
       TRY CONTRACT - PRO  <NorthEastIcon sx={{verticalAlign:'top' , fontSize:'15px', marginLeft:'5px'}} />
      </Button>
      </Box>
      </Stack>
      </Container>

      <Box mt={{ lg: 15, md: 10, sm: 8, xs: 6 }} mb={5}>
      <Container>
        <Stack spacing={{ lg: 8, md: 5, sm: 4, xs: 3 }} justifyContent='center'>
          {data.map((feature, index) => (
            <Feature
              key={index}
              index={index}
              title={Object.keys(feature)[0]}
              content={feature[Object.keys(feature)[0]].content}
              image={feature[Object.keys(feature)[0]].image}
              tryButtonLabel='TRY FOR FREE'
            />
          ))}
        </Stack>
      </Container>
    </Box>


    <Container>
      <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
      <Typography variant='h2' className='Banner2Title'>
       Pricing Plan
      </Typography>
      <Box display='flex' flexDirection='row' justifyContent='center' gap={5} mt={3}>
      <Button 
      variant='contained' 
      onClick={()=>{
        setSubscribeType('Monthly')
        getPrincing('Monthly')
      }}
      sx={{bgcolor: subscribeType === 'Monthly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
      Monthly
      </Button>
      <Button 
      onClick={()=>{
        setSubscribeType('Yearly')
        getPrincing('Yearly')
      }}
      variant='contained' 
       sx={{bgcolor: subscribeType === 'Yearly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
        Yearly
      </Button>
      </Box>

      <Grid container mt={{lg:2 , md:2 , sm:2 , xs:1}} spacing={4} mb={10} zIndex={100} justifyContent='center'  className='pricingGrid'>
      {plans.map((plan, index) => {
        const planName = Object.keys(plan)[0];
        const planData = plan[planName];
        
        // Determine which SVG to display
        const SvgComponent = index === hoveredIndex ? StandardSvg : BasicSvgComponent;

        return (
          <Grid 
            key={index} 
            item 
            xs={12} 
            sm={6} 
            md={3} 
            lg={3} 
            className='pricingCard'
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <Box position='relative'>
              <SvgComponent />
              <Box 
                className='pricingBox'
                width='100%' 
                p={2}
              >
                <Box display='flex' justifyContent='start'>
                  <Typography 
                    variant='h3' 
                    className='serviceName' 
                    color='#fff' 
                    fontSize={{ lg: '24px', md: '21px', sm: '18px', xs: '18px' }}
                  >
                    {planName}
                  </Typography>
                </Box>

                <Box textAlign='center' className='cost'>
                  <Typography 
                    variant='h5' 
                    color='#fff' 
                    className='serviceName'
                  >
                    ${planData.cost} 
                    <span style={{ fontSize: '12px' }}>
                      {subscribeType === 'Monthly' ? '/month' : '/year'}
                    </span>
                  </Typography>
                </Box>

                <Box className='servicelistBox' textAlign='left'>
                  {planData?.services?.map((service, serviceIndex) => (
                    <Typography 
                      className='serviceList' 
                      key={serviceIndex} 
                      color='#fff'
                    >
                      <CheckCircleIcon sx={{ verticalAlign: 'middle', mr: 2 }} />
                      {service?.serviceName}
                    </Typography>
                  ))}
                  <Box>
                    <Button 
                      variant='contained' 
                      fullWidth 
                      textTransform='uppercase' 
                      sx={{
                        ':hover': { bgcolor: '#ffff' }, 
                        color: '#000', 
                        borderRadius: '10px', 
                        fontWeight: 600, 
                        marginRight: '15px', 
                        bgcolor: '#fff'
                      }}
                    >
                      Choose Plan
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        );
      })}
      </Grid>
      </Box>
      </Container>

      </Box>


      <Footer />
    </div>
  )
}
