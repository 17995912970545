import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Pagination } from '@mui/material';
import Button from '@mui/material/Button';
import { Grid , Stack , Container, CardActions } from '@mui/material';
import cardImage from '../../Assets/empower__2_.webp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer';
import { eventCard } from '../../Variables/ProcessVariable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Image from '../../Assets/news.png';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { get_blog_news1, get_blog_news2, get_blog_product_company } from '../../API Service/Api';

export default function Blog() {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


  const data = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    title: `Title ${index + 1}`,
    content: `Content for item ${index + 1}. You know the feeling: You've written your first draft for an important writing assignment, and you’re ready to get it over the...`,
    date: 'September 28, 2023',
    image: cardImage,
  }));
  



  const navigate =  useNavigate();
  const [section1 , setSection1] = useState([]);
  const [section2 , setSection2] = useState([]);
  const [section2Head , setSection2Head] = useState('');
  const[product, setProduct] = useState([]);
  const[company, setCompany] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const itemsPerPage = 5;
  const producttotalPages = Math.ceil(product.length / itemsPerPage);
  const companyPages = Math.ceil(company.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProductItems = product?.slice(indexOfFirstItem, indexOfLastItem);
  const currentCompanyItems = company?.slice(indexOfFirstItem, indexOfLastItem);

  const containerRef = useRef();

  const handlePageChange = (event, newValue) => {
    setCurrentPage(newValue);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
};


const getSection1 = () => {
  const sendData = new FormData()
  sendData.append('blogNewsId1', '');
  sendData.append('section', 'Blog');
  axios({
    method: 'POST',
    url: get_blog_news1,
    data:sendData
  }).then(res => {
    if (!res.data.error) {
      setMessage(res.data.message)
      setOpen(true)
      setStatus(true)
      setColor(true)
      setSection1(res.data.data)
    } else {
      setOpen(true)
      setStatus(false)
      setColor(false)
      setMessage(res.data.message)
    }
  }).catch(err => {
    alert('Oops something went wrong ' + err)
  });
}

useEffect(() => {
  getSection1();
}, []);


const getSection2 = () => {
  const sendData = new FormData()
  sendData.append('blogNewsId2', '');
  sendData.append('section', 'Blog');
  axios({
    method: 'POST',
    url: get_blog_news2,
    data:sendData
  }).then(res => {
    if (!res.data.error) {
      setMessage(res.data.message)
      setOpen(true)
      setStatus(true)
      setColor(true)
      setSection2(res.data.data)
      setSection2Head(res.data.heading)
    } else {
      setOpen(true)
      setStatus(false)
      setColor(false)
      setMessage(res.data.message)
    }
  }).catch(err => {
    alert('Oops something went wrong ' + err)
  });
}

useEffect(() => {
  getSection2();
}, []);


const getProduct = () => {
  const sendData = new FormData()
  sendData.append('id', '');
  sendData.append('type', 'Product');
  axios({
    method: 'POST',
    url: get_blog_product_company,
    data:sendData
  }).then(res => {
    if (!res.data.error) {
      setMessage(res.data.message)
      setOpen(true)
      setStatus(true)
      setColor(true)
      setProduct(res.data.data)
    } else {
      setOpen(true)
      setStatus(false)
      setColor(false)
      setMessage(res.data.message)
    }
  }).catch(err => {
    alert('Oops something went wrong ' + err)
  });
}

useEffect(() => {
  getProduct();
}, []);


const getcompany = () => {
  const sendData = new FormData()
  sendData.append('id', '');
  sendData.append('type', 'Company');
  axios({
    method: 'POST',
    url: get_blog_product_company,
    data:sendData
  }).then(res => {
    if (!res.data.error) {
      setMessage(res.data.message)
      setOpen(true)
      setStatus(true)
      setColor(true)
      setCompany(res.data.data)
    } else {
      setOpen(true)
      setStatus(false)
      setColor(false)
      setMessage(res.data.message)
    }
  }).catch(err => {
    alert('Oops something went wrong ' + err)
  });
}

useEffect(() => {
  getcompany();
}, []);

const maxLength = 200; // Set the maximum length you want to display

// Function to truncate the text
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

  const movedtoBlog = (Blogid , BlogType) =>{
    navigate('/detailedblog')
    localStorage.setItem('Blogid', Blogid )
    localStorage.setItem('BlogType', BlogType)
  }

  const movedtoDetail = (id) =>{
    navigate('/detailedblog')
    localStorage.setItem('Blogid', id )
    localStorage.setItem('BlogType', "")
  }

  function formatDate(inputDate) {
    if (inputDate === '0000-00-00') {
      return '0000-00-00';
    }
    const dateObject = new Date(inputDate);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }
  


  

  return (
    <Box className='Banner'>
    <Header />


        <Box mt={8}>
        <Container>

        <Box textAlign='left' mb={2}>
            <Typography variant='h4'>{section1?.heading}</Typography>
        </Box>

        <Grid container spacing={5}>

        <Grid item xs={12} md={6} sm={6} lg={6}  
        order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
        display='flex' flexDirection='column' justifyContent='center' alignItems='center' alignContent='center'
        > 
      <Card sx={{width:'100%', maxHeight:700}} onClick={()=>movedtoDetail(section1?.id)}>
      <CardActionArea>
        <CardMedia
          component="img"
          height='70%'
          image={section1?.blogImage}
          alt="blog"
        />
        <CardContent>
            <Box p={1} textAlign='left'>
            <Typography textTransform='uppercase' variant="body1">
           {section1?.profilePosition}
          </Typography>
          <Typography variant="h6" fontWeight={600}>
          {section1?.blogTitle}
          </Typography>
            </Box>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid sx={{cursor:'pointer'}} item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
             <Stack textAlign='left' spacing={3}>
             <Box>
             <Typography variant="h6" fontWeight={600}>{section2Head}</Typography>
             </Box>
             {
              section2?.map((i)=>{
                return(
                  <Stack p={1} textAlign='left' spacing={1}  onClick={()=>movedtoDetail(i.id)}>
                  <Typography textTransform='uppercase' variant="caption" fontWeight={600} color='#135AD8'>
              {i.profilePosition}
                  </Typography>
                  <Typography variant="body1" width='80%'  onClick={()=>movedtoDetail(i.id)}>
               {i.blogTitle}
                  </Typography>
                  </Stack>
                )
              })
             }
</Stack>
        </Grid>

        </Grid>
  
        </Container>
        </Box>


        <Container>

        <Box display='flex' justifyContent='center' mt={10} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Product"
                        sx={{
                            bgcolor: value === 0 && '#135AC8',
                            color: value === 0 ? '#fff' : '#000',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none',
                            fontSize:'22px'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Company"
                        sx={{
                            bgcolor: value === 1 && '#135AC8',
                            color: value === 1 ? '#fff' : '#000',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none',
                            fontSize:'22px'
                        }}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>

          
            <TabPanel value={value} index={0} >
          <Stack spacing={3} mt={5} ref={containerRef} display='flex' flexDirection='column' justifyContent='center'>
        {currentProductItems.map((item) => (
          <Grid container onClick={()=>movedtoBlog(item.id, 'Product')} spacing={{lg:4 , md:4 , sm:2 , xs:0}} key={item.id} borderBottom='1px solid #f1f2fa' pb={3} sx={{ cursor:'pointer' ,":hover":{bgcolor:'#f1f2fa', borderRadius:'20px'}}}>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <img src={item.blogImage} alt={`card ${item.id}`} style={{ width: '100%', height: 200, objectFit: 'cover' }} />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <Stack spacing={1} textAlign='left'>
                <Typography variant='h5' fontWeight={600}>
                  {item.blogTitle}
                </Typography>
                <Typography variant='body1'>{truncateText(item.blogContent, maxLength)}</Typography>
                <Typography variant='subtitle2'>{formatDate(item.profileDate)}</Typography>
              </Stack>
            </Grid>
          </Grid>
        ))}
        <Box mt={4} display='flex' justifyContent='center'>
          <Pagination count={producttotalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      </Stack>
          </TabPanel>



          <TabPanel value={value} index={1} >
          <Stack spacing={3} mt={5} ref={containerRef} display='flex' flexDirection='column' justifyContent='center'>
        {currentCompanyItems.map((item) => (
          <Grid container onClick={()=>movedtoBlog(item.id, 'Company')}  spacing={{lg:4 , md:4 , sm:2 , xs:0}}  key={item.id} borderBottom='1px solid #f1f2fa' pb={3} sx={{ cursor:'pointer' ,":hover":{bgcolor:'#f1f2fa', borderRadius:'20px'}}}>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <img src={item.blogImage} alt={`card ${item.id}`} style={{ width: '100%', height: 200, objectFit: 'cover' }} />
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <Stack spacing={1} textAlign='left'>
              <Typography variant='h5' fontWeight={600}>
                {item.blogTitle}
              </Typography>
              <Typography variant='body1'>{truncateText(item.blogContent, maxLength)}</Typography>
              <Typography variant='subtitle2'>{formatDate(item.profileDate)}</Typography>
            </Stack>
          </Grid>
        </Grid>
        ))}
        <Box mt={4} display='flex' justifyContent='center'>
          <Pagination count={companyPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      </Stack>
          </TabPanel>

    </Container>


    <Footer />
    </Box>
  )
}
