import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField,} from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Footer from '../../Components/Footer/Footer';
import DemoImage from '../../Assets/HomePage_2nd/banner-4.png'
import FetaureImage from '../../Assets/Frame_31612501.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Worktestimonials } from '../../Variables/ProcessVariable';
import Avatar1 from '../../Assets/Testimonials/testi_avatar01.png';
import Avatar2 from '../../Assets/Testimonials/testi_avatar02.png';
import Avatar3 from '../../Assets/Testimonials/testi_avatar03.png';
import { get_work_individual1 ,  get_work_individual2 ,  get_work_individual3} from '../../API Service/Api';
import axios from 'axios';

export default function Individuals() {

    const settings1 = {
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        };


        const [Individual1 , setIndividual1] = useState([]);
        const [Individual2 , setIndividual2] = useState([]);
        const [Individual3 , setIndividual3] = useState([]);
        const [Individual3Heading , setIndividual3Heading] = useState('');
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("");
        const [status, setStatus] = useState();
        const [color, setColor] = useState();

        const getIndividual1 = () => {
            const sendData = new FormData()
            sendData.append('individualId1', '');
            axios({
              method: 'POST',
              url: get_work_individual1,
              data:sendData
            }).then(res => {
              if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setIndividual1(res.data.data[0])
              } else {
                setOpen(true)
                setStatus(false)
                setColor(false)
                setMessage(res.data.message)
              }
            }).catch(err => {
              alert('Oops something went wrong ' + err)
            });
          }

          const getIndividual2 = () => {
            const sendData = new FormData()
            sendData.append('individualId2', '');
            axios({
              method: 'POST',
              url: get_work_individual2,
              data:sendData
            }).then(res => {
              if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setIndividual2(res.data.data)
              } else {
                setOpen(true)
                setStatus(false)
                setColor(false)
                setMessage(res.data.message)
              }
            }).catch(err => {
              alert('Oops something went wrong ' + err)
            });
          }

          const getIndividual3 = () => {
            const sendData = new FormData()
            sendData.append('individualId3', '');
            axios({
              method: 'POST',
              url: get_work_individual3,
              data:sendData
            }).then(res => {
              if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setIndividual3(res.data.data)
                setIndividual3Heading(res.data.heading)
              } else {
                setOpen(true)
                setStatus(false)
                setColor(false)
                setMessage(res.data.message)
              }
            }).catch(err => {
              alert('Oops something went wrong ' + err)
            });
          }
        
          useEffect(() => {
            getIndividual1();
          }, []);

          useEffect(() => {
            getIndividual2();
          }, []);

          useEffect(() => {
            getIndividual3();
          }, []);
        

          const Feature = ({ title, content, image, index}) => (
            <>
            {
             index % 2 === 0 ?
             <Grid container spacing={3}>
             <Grid item xs={12} md={6} sm={6} lg={6}  
             order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
             display='flex' justifyContent='center' alignItems='center' alignContent='center'
             > 
             <Stack textAlign='left' spacing={2}>
             <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
             <Typography variant='subtitle1' fontWeight={550} className='content'>
             {content}
             </Typography>            
             </Stack>
             </Grid>
     
             <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
             <Box>
             <img src={image} alt='feature' className='featureImage' />
             </Box>
             </Grid>
             </Grid>
               :
         
               <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
               <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:1 , md:1 , sm: 1 , xs: 1}} display='flex' justifyContent='center'>
               <Box>
               <img src={image} alt='feature' className='featureImage' />
               </Box>
               </Grid>
       
               <Grid item xs={12} md={6} sm={6} lg={6}  
               order={{lg:2 , md:2 , sm: 2 , xs: 2}} 
               display='flex' justifyContent='center' id='rewrite'
               >
               <Stack textAlign='left' spacing={2}>
               <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
               <Typography variant='subtitle1' fontWeight={550} className='content'>
              {content}
               </Typography>            
               </Stack>
               </Grid>
               </Grid>
            }
            </>
           );


           const handleTryContractProSignInClick = () => {
            window.open('https://www.contractpro360.com/login', '_blank');
          };

          const handleTryContractProSignUpClick = () => {
            window.open('https://www.contractpro360.com/signup', '_blank');
          };

          const handleTryPrivacyPolicyClick = () => {
            window.open('https://www.mypoet360.com/privacypolicy', '_blank');
          };

  return (
    <Box className='Banner'>
        <Header />

       <Container>
        <Box mt={8}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
        <Box textAlign='left'>
        <Typography variant='h4' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>{Individual1.title}</Typography>
        <Typography lineHeight={1.5} variant='subtitle1' mb='20px'>
        {Individual1.content}
        </Typography>
        </Box>
        <Box flexDirection='row' gap={5} display='flex' justifyContent='start'>
        <Button variant='contained'        
        sx={{bgcolor:'#135DA8' ,width:'15ch', fontSize:'18px' , color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}} onClick={()=>handleTryContractProSignInClick()}>
        SIGN IN
        </Button>
        <Button variant='contained'  sx={{bgcolor:'#CECECE' , width:'15ch', fontSize:'18px' , color:'#000' ,fontWeight:600 , textTransform : 'capitalize', ':hover':{bgcolor:'#CECECE'}}} onClick={()=>handleTryContractProSignUpClick()}>
        SIGN UP
        </Button> 
        </Box>
        <Box mt={1} textAlign='left'>
            <Typography variant='caption'>By signing up, you agree to the <a onClick={()=>handleTryPrivacyPolicyClick()} style={{color:'#135DA8' , textDecorationColor:'#135DA8'}}> Terms and Conditions</a> and 
            <a onClick={()=>handleTryPrivacyPolicyClick()} style={{color:'#135DA8' , textDecorationColor:'#135DA8'}}> Privacy Policy</a>.
            </Typography>
        </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} position='relative' mt={{lg:0, md:0 , sm:3 , xs:4}}>
        <Box>
        <img src={Individual1.images} alt='demo' className='demoImage' />
        </Box>
        </Grid>

        </Grid>   
        </Box>
        </Container>


        <Container>
       <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
       <Typography variant='h2' className='Banner2Title'>
       Accelerate Your Progress: Collaborate with a Writing Partner for Swift Success
       </Typography>

       <Stack spacing={3}  mt={5}>

       {Individual2.map((feature, index) => (
            <Feature
              key={index}
              index={index}
              title={feature.title}
              content={feature.content}
              image={feature.images}
            />
          ))}
            </Stack>
      </Box>
      </Container>

        
      <Box mb={2} mt={2}>
      <Typography variant='h3'>
      {Individual3Heading}
       </Typography>
        </Box>
            <Box sx={{background:'#EEF2FF'}} mt={10}>
            <Container>
            <Grid container spacing={8} pb={2}>
                    {Individual3.map((feature, index) => (
                    <>
                        <Grid item xs={12} md={9} sm={9} lg={9}>
                        <Typography variant='h6' fontSize='19px' fontWeight={500} textAlign='justify' lineHeight={1.5}>{feature.content}</Typography>
                        </Grid>
            
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                        <Box gap={1} display='flex' flexDirection='column' justifyContent='start' textAlign='left'>
                        <img src={feature.images} alt='test' style={{objectFit:'contain', width:100 , height:100}} />
                        <Box>
                        <Typography variant='subtitle2' fontWeight={600}>{feature.personName}</Typography>
                        <Typography variant='caption'>{feature.personRole}</Typography>
                        </Box>
                        </Box>
                        </Grid>
                        </>
                  ))}
                

            </Grid>
            </Container>
            </Box>
           

        <Footer />
    </Box>
  )
}
